@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply lg:max-w-screen-lg;
  }

  .link {
    @apply underline text-stone-800 hover:text-stone-500 visited:text-stone-800;
  }

  p {
    @apply mb-3;
  }

  h1 {
    @apply mb-5 text-3xl;
  }

  h2 {
    @apply mb-4 text-2xl;
  }

  h3 {
    @apply mb-3 text-xl;
  }

  h4 {
    @apply mb-2 text-lg;
  }
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../public/fonts/roboto-v30-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/roboto-v30-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/roboto-v30-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../public/fonts/roboto-v30-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/roboto-v30-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../public/fonts/roboto-v30-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/roboto-v30-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/roboto-v30-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/roboto-v30-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../public/fonts/roboto-v30-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* merriweather-italic - latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/merriweather-v30-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../public/fonts/merriweather-v30-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/merriweather-v30-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/merriweather-v30-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/merriweather-v30-latin-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../public/fonts/merriweather-v30-latin-italic.svg#Merriweather") format("svg"); /* Legacy iOS */
}
